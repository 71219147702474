import React from 'react';
import './Header.css';
// import logo from './logo.png'; // Assuming you have a logo image in the same directory

let logo = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAt2h3bgQUXqHDL_jszk41YgE0_XXRsCdVdg&s'
const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Layapriya Logo" className="logo" />
        <h1 className="title">Layapriya School of Performing Arts</h1>
      </div>
    </header>
  );
};

export default Header;
