import React, { useEffect, useState } from 'react';
import './HeroSection.css';
import { useNavigate } from 'react-router-dom';
import yellow1 from "./logo/yellow1.png";
import whiteBG from "./logo/whiteBG.png";
import Jagathi from "./logo/Jagathi.jpeg";
import green from "./logo/green.jpg";
import golden from "./logo/golden.png";


const HeroSection = () => {
  const instruments = ["Mridangam", "Flute", "Kartal"];
  const [currentText, setCurrentText] = useState('');
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const [waitBeforeDelete, setWaitBeforeDelete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const typeWriter = () => {
      const fullText = `${instruments[index]}`;
      
      if (!isDeleting && currentText !== fullText) {
        setCurrentText(fullText.substring(0, currentText.length + 1));
      } else if (!isDeleting && currentText === fullText && !waitBeforeDelete) {
        setWaitBeforeDelete(true);
        setTimeout(() => {
          setIsDeleting(true);
          setWaitBeforeDelete(false);
        }, 1000);
      } else if (isDeleting && currentText !== '') {
        setCurrentText(fullText.substring(0, currentText.length - 1));
      } else if (isDeleting && currentText === '') {
        setIsDeleting(false);
        setIndex((prevIndex) => (prevIndex + 1) % instruments.length);
        setTypingSpeed(150);
      }
    };

    const timer = setTimeout(() => {
      typeWriter();
    }, typingSpeed);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, instruments, index, typingSpeed, waitBeforeDelete]);

  return (
   <div>
     <div className="header-container">
    <center>
       <img src={golden} className="logo"/>
    </center>
      </div>
    <section className="hero">
      
      <h1 className="animated-text">
        {/* Transform sound into devotion, <br /> embrace the beauty of music through <br />
        {currentText}
        <span className="cursor">|</span> */}
      </h1>
      {/* <div className="button-container">
        <button className="animated-button" onClick={() => navigate("/register")}>
          <svg xmlns="http://www.w3.org/2000/svg" className="arr-2" viewBox="0 0 24 24">
            <path
              d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
            ></path>
          </svg>
          <span className="text">R E G I S T E R N O W</span>
          <span className="circle"></span>
          <svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
            <path
              d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
            ></path>
          </svg>
        </button>
      </div> */}
    </section>
   </div>
  );
};

export default HeroSection;
