import React from 'react';
import { FaChalkboardTeacher, FaUserGraduate, FaMusic } from 'react-icons/fa';
import "./HeroSection.css";
import { useNavigate } from 'react-router-dom';

export default function TabsSection() {
    const navigate = useNavigate();
  return (
    <>
      <div className="menu-tabs">
        <div className="menu-tab" onClick={() => navigate("/tutors")} style={{cursor:"pointer"}}>
          <FaChalkboardTeacher className="tab-icon" />
          <h3 className="tab-title">Tutors</h3>
        </div>
        <div className="menu-tab" onClick={() => navigate("/gurus")} style={{cursor:"pointer"}}>
          <FaUserGraduate className="tab-icon" />
          <h3 className="tab-title">Gurus</h3>
        </div>
        <div className="menu-tab" onClick={() => navigate("/chamber-concerts")} style={{cursor:"pointer"}}>
          <FaMusic className="tab-icon" />
          <h3 className="tab-title">Chamber Concerts</h3>
        </div>

        <div className="menu-tab" onClick={() => navigate("/mridangam")} style={{cursor:"pointer"}}>
          <FaMusic className="tab-icon" />
          <h3 className="tab-title">Mridangam</h3>
        </div>
      </div>
    </>
  );
}
