// Login.js
import React, { useState, useContext } from 'react';
import { login } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import LayoutComponent from './LayoutComponent'; // Adjust path as necessary
import { TextField, Button, CircularProgress, Snackbar, Alert, FormControlLabel, Checkbox } from '@mui/material';

const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const { data } = await login({ email: emailOrPhone, password });
      if (data.status === 'success') {
        localStorage.setItem('token', data.token);
        setAuthState({ user: data.user, isAuthenticated: true });
        navigate('/dashboard');
      } else {
        setError(data.message);
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Internal Server Error! Please try again later');
    } finally {
      setLoading(false);
    }
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleForgotPassword = () => {
    alert('Forgot password feature is not implemented yet.');
  };

  return (
    <LayoutComponent
      imageUrl="https://static.vecteezy.com/system/resources/previews/036/798/295/non_2x/ai-generated-wooden-guitar-pottery-bowl-maracas-a-celebration-of-cultures-generated-by-ai-free-photo.jpg"
      title="Welcome!"
    >
      <p>Log In your account</p>
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
        <TextField
          label="Email or Mobile Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={emailOrPhone}
          onChange={(e) => setEmailOrPhone(e.target.value)}
          required
          error={!!error}
          helperText={error}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div style={{display:'flex', flexDirection:'row',     justifyContent: 'space-between', alignItems: 'center'
}}>
        <FormControlLabel
          control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange} />}
          label="Remember me"
        />
        <Button
          type="button"
          variant="text"
          color="primary"
          onClick={handleForgotPassword}
          sx={{ mb: 1 }}
        >
          Forgot password?
        </Button>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Log In'}
        </Button>
      </form>

      <div className="line-container">
        <span className="line-text">Or</span>
    </div>

    <div className="additionalText">
      Don't have an account? <p className='highlightedText' onClick={() => navigate('/register')}>Sign Up</p>
    </div>
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError('')}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </LayoutComponent>
  );
};

export default Login;
