import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/auth';
import AuthContext from '../../context/AuthContext';
import DashboardHome from './StudentDashboard/DashboardHome';
import './Dashboard.css'; // Import CSS for styling

const Dashboard = () => {
    const { authState, setAuthState } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            localStorage.removeItem('token');
            setAuthState({ user: null, isAuthenticated: false });
            navigate('/');
        } catch (err) {
            console.error('Logout failed', err);
        }
    };

    if (!authState.isAuthenticated) {
        navigate("/register")
    }

    return (
        <div className="dashboard-container">
            <header className="header">
                <div className="logo">Layapriya Student Control</div>
                <nav className="nav">
                    <ul>
                        <li><a href="#dashboard">Dashboard</a></li>
                        <li><a href="#profile">Profile</a></li>
                        <li><a href="#settings">Settings</a></li>
                    </ul>
                </nav>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </header>
            <main className="main-content">
                <section className="welcome-section">
                    <h1>Welcome, {authState?.user?.firstName}</h1>
                </section>
                <DashboardHome />
            </main>
        </div>
    );
};

export default Dashboard;
