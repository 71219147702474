import React from 'react';
import './RegistrationOptions.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { useNavigate } from 'react-router-dom';

const RegistrationOptions = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();

  return (
    <div className="registration-container" data-aos="fade-up">
      <h1 className="registration-title">Register for Classes</h1>
      <p className="registration-subtitle">Choose your preferred class format</p>
      <div className="options-container">
        <div className="option-item" data-aos="fade-up" data-aos-delay="100">
          <h3>Mridangam</h3>
          <p>In-person in Vancouver / Online / Hybrid</p>
        </div>
        <div className="option-item" data-aos="fade-up" data-aos-delay="200">
          <h3>Rhythm</h3>
          <p>In-person in Vancouver / Online / Hybrid</p>
        </div>
        <div className="option-item" data-aos="fade-up" data-aos-delay="300">
          <h3>Karnatik Vocal</h3>
          <p>Online</p>
        </div>
        <div className="option-item" data-aos="fade-up" data-aos-delay="400">
          <h3>Karnatik Flute</h3>
          <p>Online</p>
        </div>
      </div>
      <button className="button" onClick={() => navigate("/register")}>
        Register Now
        <div className="arrow">›</div>
      </button>
    </div>
  );
};

export default RegistrationOptions;
