import React, { useEffect } from "react";
import "./TutorProfile.css"; // Custom CSS for styling
import keerthy from "./media/keerthy.JPG";
import mohan from "./media/mohan.jpeg";
import athman from "./media/athman.png";
import GuruHome from "../Guru/GuruHome";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import SocialMediaPage from "../../SocialMedia/SocialMediaPage";

const TutorProfile = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="tutor-container">
    

      {/* Keerthy Raghavan */}
      <section className="about-section" data-aos="fade-up">
        <div className="about-content">
          <h2 style={{fontSize:"42px"}}>Gorur Keerthy Raghavan</h2>
          <h3 style={{fontStyle:"italic"}}>Mridangam Artiste (Karnatik Percussion), Composer, Educator, Collaborator, Seeker </h3>
          <h3 style={{fontStyle:"italic"}}>Founder & Artistic Director: Laya Priya School of Performing Arts Shruti Laya Bhedam - Ensemble</h3>
          <p style={{fontSize:"1.4rem"}}>
            Keerthy Raghavan is a percussionist and composer of percussion ensembles hailing from the quaint town of Gorur in India's southern expanse. Born into a family steeped in the rich heritage of music and literature, his artistic journey was inevitably shaped by this profound cultural heritage.
          </p>
        </div>
        <div className="about-image-container">
          <img src={keerthy} alt="Gorur Keerthy Raghavan" className="about-image" />
        </div>

        {/* Family Section */}
        <section className="guiding-light-section" data-aos="fade-up">
          <h2>FAMILY</h2>
          <div className="guiding-light-content">
            <p>
              Keerthy Raghavan is a percussionist and composer of percussion ensembles hailing from the quaint town of Gorur in India's southern expanse. Born into a family steeped in the rich heritage of music and literature, his artistic journey was inevitably shaped by this profound cultural heritage.
            </p>
          </div>
        </section>

        {/* Music Section */}
        <section className="guiding-light-section" data-aos="fade-up">
          <h2>MUSIC</h2>
          <div className="guiding-light-content">
            <p>
              <b>KarnatiK music</b> is a classical music tradition originating from South India. Known for its intricate melodies, complex rhythms, and emphasis on improvisation, it is one of the world's most sophisticated musical systems. Steeped in ancient Indian philosophy and aesthetics, Karnatik music offers a profound and spiritually enriching experience for both musicians and listeners.
            </p>
          </div>
        </section>

        {/* Musical Journey Section */}
        <section className="guiding-light-section" data-aos="fade-up">
          <h2>MUSICAL JOURNEY</h2>
          <div className="guiding-light-content">
            <p>
              Keerthy Raghavan's musical quest commenced under the tutelage of Shri Rajakesari, a distinguished All India Radio artist and disciple of the legendary Guru Karaikudi R. Mani. Nestled within the prestigious Shruti Laya Kendra Bangalore Chapter, Raghavan's foundational training was steeped in the rich tradition of the Mridangam. Renowned for his intricate rhythmic patterns and powerful yet nuanced strokes, Guru Karaikudi Mani's unique style, known as "Mani Bani," is a cornerstone of Karnatik percussion. Raghavan was fortunate to receive direct guidance from the maestro himself, mastering the intricacies of this fine art form.
            </p>
          </div>
        </section>

        {/* Experience Section */}
        <section className="guiding-light-section" data-aos="fade-up">
          <h2>EXPERIENCE</h2>
          <div className="guiding-light-content">
            <p>
              As a seasoned percussionist, Keerthy has showcased his talent in concerts across India, the Middle East, and Africa, actively engaging in various musical scenes and receiving recognition for community initiatives. Notable performances include the International Yoga Day at the United Nations in Nairobi. In Vancouver, BC, he is deeply engaged in cultural events such as the Mushtari Begum Festival, Indian Classical Music Society of Vancouver, South Asian Music Festival, Times will Tell Society Arts, Oakridge Adventist Church, Jazz initiatives by Canadian Music Center, Spice Radio, Hindu Heritage, and many more.
            </p>
          </div>
        </section>

        {/* Passion Section */}
        <section className="guiding-light-section" data-aos="fade-up">
          <h2 style={{fontStyle:"italic"}}>A PASSION BEHIND FOUNDING ENSEMBLE AND SCHOOL</h2>
          <div>
            <h3>SHRUTI LAYA BHEDAM</h3>
            <div className="guiding-light-content">
              <p>
                An ardent advocate for peace and unity, Keerthy excels in curating thematic musical experiences that advocate for global harmony and stand against racism. As the visionary behind "Shruti Laya Bhedam," he endeavors to educate and raise awareness about rhythm while fostering cross-cultural collaborations that transcend boundaries. Through his ensemble, he seeks to deliver high-level percussion & melodic experiences to audiences while relentlessly striving for musical excellence.
              </p>
            </div>
          </div>
          <div>
            <h3>LAYA PRIYA SCHOOL OF PERFORMING ARTS</h3>
            <div className="guiding-light-content">
              <p>
                Through the invaluable guidance of his mentors and gurus, Raghavan established a school dedicated to Karnatik percussion and music in Vancouver. Beyond his own performances, he is passionately committed to cultivating the next generation of musicians through his teaching and workshops in rhythm theory, Mridangam as percussion, and engaging lecture demonstrations for aspiring dancers. His innovative approach, seamlessly blending traditional Indian percussion with contemporary influences, has garnered critical acclaim and a dedicated following.
              </p>
            </div>
          </div>

      <SocialMediaPage/>

        </section>

        <section className="videos-section">
        <div className="video-list">
          <div className="video-item">
            <iframe
              src="https://www.youtube.com/embed/JjvceXON3Ks" // Replace with the actual YouTube video ID
              title="Shruti Laya Bhedam Ensemble"
              className="video"
              allowFullScreen
            ></iframe>
          </div>

          <div className="video-item">
            <iframe
              src="https://www.youtube.com/embed/f4RjNJ9h-NE" // Replace with the actual YouTube video ID
              title="Shruti Laya Bhedam Ensemble"
              className="video"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
      </section>


      <header>
        <h2 data-aos="fade-up">Tutors</h2>
      </header>


      {/* Putty R Mohan */}
      <section className="about-section-reverse" data-aos="fade-up">
        <div className="about-content">
          <h2  style={{fontSize:"42px"}}>Putty R Mohan - A Karnatik Vocalist</h2>
          <p>
          is a profound Karnatik vocalist with a deep-rooted foundation in traditional classical 
music. Trained under the guidance of esteemed mentors like Vidwan R.N. Thyagarajan and 
Vidushi Smt. Jahnavi Jayaprakash, Putty has honed his skills over the decades.
Putty R Mohan's distinguished career includes captivating performances on All India Radio and 
Doordarshan, as well as collaborations with renowned Bharatanatyam dancers such as Vani 
Ganapathi, Kiran Subramaniam, Narmada, and Revathi Narasimhan. He has graced prestigious 
stages across India, including Sri Chowdaiah Memorial Hall in Bangalore and Sri Krishna Gana 
Sabha in Chennai. His impressive resume also includes a performance at Rashtrapathi Bhavan, 
New Delhi, in the presence of former President Dr. Abdul Kalam. Moreover, Putty has showcased 
his talent internationally, performing in the United Kingdom and the Middle East. He even served 
as the lead vocalist for a dance production by the company 'India Dance Wales' in the United 
Kingdom
Beyond his illustrious performing career, Putty R Mohan is a dedicated educator, sharing his passion 
for Karnatik music with students of all ages across the globe. As a founding member of the 
Rajarajeshwarinagar Cultural Association, he has been instrumental in promoting classical music 
through various initiatives. In addition to his in-person classes in Bangalore, Putty R Mohan is 
dedicated to nurturing young talent through his online Karnatik music classes offered by the Laya 
Priya School of Performing Arts. He has also played a pivotal role in developing the school's 
curriculum, ensuring that students are well-prepared for future musical examinations
               </p>
        </div>
        <div className="about-image-container">
          <img src={mohan} alt="Putty R Mohan" className="about-image" />
        </div>
      </section>

      {/* Athman Putty Mohan */}
      <section className="about-section" data-aos="fade-up">
        <div className="about-content">
          <h2  style={{fontSize:"42px"}}>Athman Putty Mohan: A Rising Flute Prodigy</h2>
          <p>
          A young musical prodigy, a raising star has been captivating audiences with his exceptional flute 
skills in India. His musical journey began at the tender age of three, when his parents recognized his 
innate talent. To foster his musical growth, Athman was homeschooled until his 10th grade, 
allowing him to focus on his passion for music and his father a profound Karnatik classical 
performer and a teacher started flute lessons for him at the age of 5. Furthermore, Athman has 
undergone rigorous training under renowned mentors like Vid. Sampagodu Vighnaraja and the 
Flute Brothers Vid. Heramb and Vid. Hemanth, honing his flute-playing skills. His debut 
performance at the Rajarajeshwarinagar Cultural Association at the age of 10 marked the 
beginning of a promising career. 
          </p>


          <p>

          Athman's dedication and talent have been recognized with prestigious awards, including 
scholarships from CCRT, Central Govt. of India and the Academy of Music, Bangalore. He is also 
the winner of flute competitions securing the top prizes. His musical repertoire extends beyond solo 
performances. Athman has graced prestigious stages across India, including Gayana Samaja, 
Chowdiah Memorial Hall, Rajarajeshwarinagar Cultural Association, and the Indian Music 
Experience. He has also participated in renowned events like the Sri Palani Subramaniam Pillai 
Global percussion challenge and chamber concerts.
Athman is a dedicated tutor at the Laya Priya School of Performing Arts. He is passionate about 
sharing his knowledge and inspiring students to explore the intricacies of Karnatik music. 
With his ability to connect with present generation, Athman creates a supportive and encouraging 
learning environment. He provides personalized instruction, focusing on both technical skills and 
emotional expression. Athman's dedication and expertise are making a significant impact on the 
next generation of Karnatik musicians
          </p>
        </div>
        <div className="about-image-container">
          <img src={athman} alt="Athman Putty Mohan" className="about-image" />
        </div>
      </section>

      
    </div>
  );
};

export default TutorProfile;
