import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import HeroSection from "./heroSection/HeroSection";
import InstrumentSection from "./InstrumentSection/InstrumentSection";
import RegistrationCTA from "./RegistrationCTA/RegistrationCTA";
import AboutLayapriya from "./About/AboutLayapriya";
import FocusOnKarnatik from "./FocusOnKarnatik/FocusOnKarnatik";
import Header from "./header/Header";
import { useNavigate } from "react-router-dom";
import GuruProfile from "./Guru/GuruProfile";
import TutorProfile from "./Tutors/TutorProfile";
import ShrutiLayaBhedam from "../ShrutiLayaBhedam/ShrutiLayaBhedam";
import ContactUs from "./ContactUs/ContactUs";
import Layapriya from "./Layapriya/Layapriya";
import GuruHome from "./Guru/GuruHome";
import RegistrationOptions from "../RegistrationOptions/RegistrationOptions";
import SocialMediaPage from "../SocialMedia/SocialMediaPage";
import TabsSection from "./heroSection/TabsSection";

export default function HomePage() {
  const navigate = useNavigate();
  // useEffect(() => {
  //     const currentTime = new Date();
  //     const targetTime = new Date("2024-10-11T16:30:00Z"); // Convert IST to UTC (10:00 PM IST = 4:30 PM UTC)

  //     console.log(currentTime, targetTime)
  //     if (currentTime < targetTime) {
  //         // If the current time is before 11th October 2024, 10:00 PM IST, redirect to the register page
  //         navigate("/register");
  //     }
  // }, [navigate]);

  return (
    <>
      {/* <Header/> */}
      <HeroSection />
      <TabsSection/>

      <Layapriya />
      {/* <TutorProfile /> */}
    
     
      <ShrutiLayaBhedam />

      <footer>
        <center>
          {/* <GuruHome /> */}

        </center>
      </footer>

      {/* <FocusOnKarnatik/> */}

      {/* <InstrumentSection/> */}

      {/* <RegistrationCTA/> */}
      {/* <AboutLayapriya/> */}

     <RegistrationOptions/>
<ContactUs />

      <Footer /> 
    </>
  );
}
