import axios from 'axios';

const API_BASE_URL = 'https://layapriya.bringecommerce.site/api/layapriya';

export const register = (userData) => axios.post(`${API_BASE_URL}/register`, userData);

export const login = (credentials) => axios.post(`${API_BASE_URL}/login/student`, credentials);

export const validateToken = () => axios.get(`${API_BASE_URL}/validate-token`, {
    headers: { Authorization: localStorage.getItem('token') }
});

export const logout = () => axios.get(`${API_BASE_URL}/logout`);
