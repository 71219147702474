// LayoutComponent.js
import React from "react";
import { Box, Typography, Container } from "@mui/material";
import "./styles.css";

const LayoutComponent = ({ imageUrl, title, children }) => {
  return (
    <Container sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <Box
        sx={{
          flex: 1,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: { xs: "none", md: "block" },
        }}
      />
      
      <Box
        sx={{
          flex: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
         
          {children}
        </div>
      </Box>
    </Container>
  );
};

export default LayoutComponent;
