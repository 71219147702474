import React from "react";
import './PhotoDisplay.css';
import halebeedu from "./halebeedu.webp"

export default function Mridangam() {
  return (
    <>
     <div className="tutor-container">
      <section className="about-section" data-aos="fade-up">
        <div className="about-content" style={{ textAlign: "center" }}>
          <h2>Mridangam</h2>
          <p style={{ fontSize: "22px", textAlign: "center" }}>
            The mridangam, a divine instrument revered in ancient Hindu texts,
            is often referred to as "Deva Vadyam," the instrument of the gods.
            Legends associate it with deities like Nandi, Ganesha and vishnu.
            Its intricate construction and mathematical precision earned it the
            title of "Raja Vadyam," the king of percussion instruments. This
            rhythmic powerhouse, with its deep bass and sharp treble, has been
            an essential component of Karnatik music for centuries, captivating
            audiences with its mesmerizing rhythms and soulful expressions
            <br />
            The mridangam's unique construction and playing techniques allow for
            a wide range of sounds, from deep, resonant bass notes to sharp,
            percussive accents. The two heads of the drum, tuned to different
            pitches, produce distinct sounds when struck with the fingers and
            palms.The mridangam's rhythmic patterns are deeply intertwined with
            the cyclical nature of the universe, as described in Hindu
            cosmology. The rhythmic cycles of the mridangam mirror the cosmic
            rhythms of creation, preservation, and destruction The player's
            skill in manipulating these sounds creates intricate rhythmic
            patterns that enhance the overall musical experience.
            <br />
            The mridangam is not just a percussion instrument; it's a partner in
            the musical dialogue, responding to the nuances of the melody and
            driving the rhythmic energy of the performance. Sir C.V. Raman, the
            renowned Nobel laureate, conducted pioneering research on the
            acoustics the mridangam. He delved deep into the physics behind the
            instrument's sound production, exploring the vibrations and
            harmonics produced by the drumheads. His findings shed light on the
            relationship between the pitch of the mridangam and the underlying
            musical scales.
          </p>
        </div>
      </section>


      <div className="photo-container">
      <div className="photo-wrapper">
        <img
          src={halebeedu} // Replace with the actual image URL
          alt="Intricate carvings of Hoysaleswara Temple"
          className="photo"
        />
        <div className="photo-credit">Photo Credit: Karthik K Samprati</div>
      </div>
      <p className="photo-description">
        This captivating image captures the intricate carvings adorning the 11th-century Hoysaleswara Temple in Halebidu, Karnataka, India. Among the myriad of sculptures depicting gods, goddesses, and mythical creatures, one can spot the iconic Mridangam, a testament to the deep-rooted connection between music and spirituality in ancient India.
      </p>
    </div>
    </div>
    </>
  );
}
