import React, { useState } from "react";
import useSWR from "swr";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


import CryptoJS from "crypto-js";

const generateUniqueId = (eventName, eventDescription, timeZone) => {
  const hash = CryptoJS.SHA256(`${eventName}-${eventDescription}-${timeZone}`);
  return hash.toString(CryptoJS.enc.Hex).substring(0, 32);
};

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function ScheduleClasses() {
  const { data, error } = useSWR(
    "https://layapriya.bringecommerce.site/api/layapriya/registration-details-v2/get-student-data",
    fetcher
  );
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [filter, setFilter] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [formValues, setFormValues] = useState({
    eventName: "",
    eventDescription: "",
    fromTime: null,
    toTime: null,
    timeZone: "Asia/Kolkata",
    uniqueId: "",
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  if (error)
    return (
      <Typography variant="h6" color="error">
        Failed to load data
      </Typography>
    );
  if (!data)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  if(data.data.length==0){
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <p><center>
        Currently, there are no registered students. Please check back later
        </center> </p>
      </Box>
    );
  }

  const students = data.data;

  const handleSelect = (student) => {
    setSelectedStudents((prevSelected) => {
      if (prevSelected.some((s) => s._id === student._id)) {
        return prevSelected.filter((s) => s._id !== student._id);
      } else {
        return [...prevSelected, student];
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedStudents(filteredStudents);
    } else {
      setSelectedStudents([]);
    }
  };

  const handleCreateMeeting = () => {
    if (selectedStudents.length === 0) {
      setSnackbarMessage("Please select at least one email.");
      setSnackbarOpen(true);
      return;
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFormChange = (field, value) => {
    setFormValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [field]: value,
      };
      // Update uniqueId only when eventName, eventDescription or timeZone changes
      if (field === "eventName" || field === "eventDescription" || field === "timeZone") {
        newValues.uniqueId = generateUniqueId(newValues.eventName, newValues.eventDescription, newValues.timeZone);
      }
      return newValues;
    });
  };
  

  const handleFormSubmit = async () => {
    const emailList = selectedStudents.map((student) => ({ email: student.email }));
  
    if (!formValues.eventName || !formValues.fromTime || !formValues.toTime || !formValues.uniqueId) {
      setSnackbarMessage("Please fill in all required fields.");
      setSnackbarOpen(true);
      return;
    }
  
    // Format the date values to the desired format
    const formatDate = (date) => date ? date.toISOString() : null;
  
    try {
      const response = await fetch("https://layapriya.bringecommerce.site/api/layapriya/create-meet", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailList,
          event: {
            eventName: formValues.eventName,
            eventDescription: formValues.eventDescription,
            fromTime: formatDate(formValues.fromTime),
            toTime: formatDate(formValues.toTime),
            timeZone: formValues.timeZone,
            uniqueId: formValues.uniqueId,
          }
        }),
      });
  
      if (response.ok) {
        setSnackbarMessage("Meeting created successfully!");
      } else {
        setSnackbarMessage("Failed to create meeting. Please try again.");
      }
      setSnackbarOpen(true);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error creating meeting:", error);
      setSnackbarMessage("Error creating meeting. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const filteredStudents = students.filter((student) => {
    if (filter === "") return true;
    return filter === "paid" ? student.paid : !student.paid;
  });

  return (
    <Box sx={{ p: 3, backgroundColor: "#f4f6f8", minHeight: "100vh" }}>
      <Typography
        variant="h4"
        sx={{ mb: 3, textAlign: "center", fontWeight: "bold", color: "#3f51b5" }}
      >
        Students List
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <FormControl variant="outlined" sx={{ minWidth: 300 }}>
          <InputLabel>Filter by Payment Status</InputLabel>
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            label="Filter by Payment Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="unpaid">Unpaid</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateMeeting}
          sx={{
            backgroundColor: "#3f51b5",
            "&:hover": {
              backgroundColor: "#303f9f",
            },
          }}
        >
          Create Meeting Link
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        elevation={6}
        sx={{ borderRadius: 2, backgroundColor: "#ffffff" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox
                  indeterminate={
                    selectedStudents.length > 0 &&
                    selectedStudents.length < filteredStudents.length
                  }
                  checked={filteredStudents.length > 0 && selectedStudents.length === filteredStudents.length}
                  onChange={handleSelectAll}
                />
              </StyledTableCell>
              {/* <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell> */}
              <StyledTableCell>Email</StyledTableCell>
              {/* <StyledTableCell>Mobile Number</StyledTableCell>
              <StyledTableCell>City</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Country</StyledTableCell>
              <StyledTableCell>Pincode</StyledTableCell> */}
              <StyledTableCell>Payment Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student) => (
              <StyledTableRow key={student._id}>
                <TableCell>
                  <Checkbox
                    checked={selectedStudents.some((s) => s._id === student._id)}
                    onChange={() => handleSelect(student)}
                  />
                </TableCell>
                {/* <TableCell>{student.firstName}</TableCell>
                <TableCell>{student.lastName}</TableCell> */}
                <TableCell>{student.email}</TableCell>
                {/* <TableCell>{student.mobileNumber}</TableCell>
                <TableCell>{student.city}</TableCell>
                <TableCell>{student.state}</TableCell>
                <TableCell>{student.country}</TableCell>
                <TableCell>{student.pincode}</TableCell> */}
                <TableCell>
                  <Typography color={student.paid ? "success.main" : "error.main"}>
                    {student.paid ? "Paid" : "Unpaid"}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Create Meeting</DialogTitle>
        <DialogContent>
          <TextField
            label="Event Name"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formValues.eventName}
            onChange={(e) => handleFormChange("eventName", e.target.value)}
          />
          <TextField
            label="Event Description"
            fullWidth
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            value={formValues.eventDescription}
            onChange={(e) => handleFormChange("eventDescription", e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={(props) => <TextField fullWidth margin="normal" {...props} />}
              label="From Time"
              value={formValues.fromTime}
              onChange={(newValue) => handleFormChange("fromTime", newValue)}
            /> &nbsp;
            <DateTimePicker
              renderInput={(props) => <TextField fullWidth margin="normal" {...props} />}
              label="To Time"
              value={formValues.toTime}
              onChange={(newValue) => handleFormChange("toTime", newValue)}
            />
          </LocalizationProvider>


          <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel>Time Zone</InputLabel>
      <Select
        value={formValues.timeZone}
        onChange={(e) => handleFormChange("timeZone", e.target.value)}
        label="Time Zone"
      >
        <MenuItem value="Asia/Kolkata">Asia/Kolkata</MenuItem>
        <MenuItem value="America/New_York">America/New_York</MenuItem>
        <MenuItem value="Europe/London">Europe/London</MenuItem>
        {/* Add more time zones as needed */}
      </Select>

    </FormControl>
          <TextField
            label="Unique ID"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formValues.uniqueId}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            Create Meeting
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
