import React, { useState } from "react";
import useSWR from "swr";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  IconButton,
  InputAdornment,
  Tooltip,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import CryptoJS from "crypto-js";
import axios from "axios";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function EnrolledStudents() {
  const { data, error, mutate } = useSWR(
    "https://layapriya.bringecommerce.site/api/layapriya/registration-details-v2/get-student-data",
    fetcher
  );

  const [openPopup, setOpenPopup] = useState(false);
  const [popupLoading, setPopupLoading] = useState(true);
  const [sharingLoading, setSharingLoading] = useState(false); // Loader for sharing credentials
  const [generatedCredentials, setGeneratedCredentials] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [students, setStudents] = useState([]); // Local state for student data

  const generateRandomPassword = () => {
    return CryptoJS.lib.WordArray.random(12).toString(CryptoJS.enc.Hex); // 12-character stronger password
  };

  const handleCreateCredentials = (student) => {
    setOpenPopup(true);
    setPopupLoading(true);

    setTimeout(() => {
      const randomPassword = generateRandomPassword();
      setGeneratedCredentials({
        username: student.email,
        password: randomPassword,
        _id: student._id,
      });
      setPopupLoading(false);
    }, 2000);
  };

  const handleResetPassword = () => {
    const newPassword = generateRandomPassword();
    setGeneratedCredentials((prev) => ({
      ...prev,
      password: newPassword,
    }));
  };

  const handleShareCredentials = async () => {
    setSharingLoading(true); // Start loader
    try {
      const response = await axios.post(
        `https://layapriya.bringecommerce.site/api/layapriya-v2/create-credentials`,
        {
          email: generatedCredentials.username,
          password: generatedCredentials.password,
          _id: generatedCredentials._id,
        }
      );
      if (response.data.statusCode === 200) {
        setSnackbarMessage(response.data.message);
        // Update local student data
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student._id === generatedCredentials._id
              ? { ...student, credentialsPassed: 1 }
              : student
          )
        );
      } else {
        setSnackbarMessage("Failed to share credentials.");
      }
    } catch (error) {
      console.error("Error sharing credentials:", error);
      setSnackbarMessage("Error sharing credentials.");
    }
    setSharingLoading(false); // End loader
    setSnackbarOpen(true);
    setOpenPopup(false);
  };

  if (error)
    return (
      <Typography variant="h6" color="error">
        Failed to load data
      </Typography>
    );
  if (!data)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  if (data.data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <p>
          <center>Currently, there are no registered students. Please check back later.</center>
        </p>
      </Box>
    );
  }

  if (!students.length && data) {
    setStudents(data.data); // Initialize local state when data is loaded
  }

  return (
    <Box sx={{ p: 3, backgroundColor: "#f4f6f8", minHeight: "100vh" }}>
      <Typography
        variant="h4"
        sx={{ mb: 3, textAlign: "center", fontWeight: "bold", color: "#3f51b5" }}
      >
        Students List
      </Typography>

      <TableContainer component={Paper} elevation={6} sx={{ borderRadius: 2, backgroundColor: "#ffffff" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>City</StyledTableCell>
              <StyledTableCell>Payment Status</StyledTableCell>
              <StyledTableCell>Create Credentials</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <StyledTableRow key={student._id}>
                <TableCell>{student.sessionObj?.customer_details?.name || '~'}</TableCell>
                <TableCell>{student.email}</TableCell>
                <TableCell>{student?.sessionObj?.customer_details.address?.city || '~'}</TableCell>
                <TableCell>
                  <Typography color={student.paid ? "success.main" : "error.main"}>
                    {student.paid ? "Paid" : "Unpaid"}
                  </Typography>
                </TableCell>
                <TableCell>
                  {((student.credentialsPassed == 0) && student.paid)  ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleCreateCredentials(student)}
                    >
                      Create Credentials
                    </Button>
                  ) :
                  (!student.paid) ? 
                  <>
                  <p>Student yet to pay</p>
                  </>
                  :
                  (
                    <p>Credentials Already shared to user</p>
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Popup for Credentials */}
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#3f51b5" }}>
            Generated Credentials
          </Typography>
        </DialogTitle>
        <DialogContent>
          {popupLoading || sharingLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Typography variant="body1" sx={{ mb: 2, fontWeight: "bold" }}>
                <span style={{ color: "#3f51b5" }}>Username:</span> {generatedCredentials.username}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, fontWeight: "bold" }}>
                <span style={{ color: "#3f51b5" }}>Password:</span>
                <TextField
                  value={generatedCredentials.password}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Reset Password">
                          <IconButton onClick={handleResetPassword}>
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                    style: { fontFamily: "monospace", fontSize: "1rem", letterSpacing: 2 },
                  }}
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
              </Typography>
            </Box>
          )}
        </DialogContent>
        {!popupLoading && !sharingLoading && (
          <DialogActions>
            <Button onClick={() => setOpenPopup(false)} color="secondary" variant="outlined" sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handleShareCredentials} color="primary" variant="contained">
              Share Credentials
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
