import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import { CalendarOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";


const DashboardHome = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if(window.localStorage.getItem("key-email-id")){
          const response = await axios.post(
            "https://layapriya.bringecommerce.site/api/layapriya/student-dashboard-api",
            { emailorPhone: window.localStorage.getItem("key-email-id") }
          );
          if (response.data.statusCode === 202) {
            setEvents(response.data.availableEvents);
          } else if (response.data.statusCode === 404) {
            setError("No events found.");
          } else {
            setError("An error occurred while fetching the events.");
          }
        }
       
      } catch (error) {
        setError("An error occurred while fetching the events.");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-title">Your Classes</h2> <br/>
      {error && <p className="error-message">{error}</p>}
      <div className="events-list">
        {events.length > 0 ? (
          events.map((event) => (
            <div key={event.id} className="event-card">
              <h3 className="event-summary">{event.summary}</h3>
              <p className="event-description">{event.description}</p>
              <p className="event-time">
                {new Date(event.start.dateTime).toLocaleString()} -{" "}
                {new Date(event.end.dateTime).toLocaleString()}
              </p>
              {event.hangoutLink && (
                <a
                  href={event.hangoutLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="join-button"
                >
                  <Button
                    type="dashed"
                    icon={<VideoCameraOutlined />}
                    target="_blank"
                  >
                    Join Meeting
                  </Button>
                </a>
              )}
            </div>
          ))
        ) : (
          <p>No upcoming classes.</p>
        )}
      </div>
    </div>
  );
};

export default DashboardHome;
