import React, { useState, useMemo } from "react";
import useSWR from "swr";
import { Button, Space } from "antd";
import { CalendarOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, CircularProgress, Box, Grid } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import axios from "axios";
import moment from "moment";

// Fetcher function for useSWR
const fetcher = url => axios.get(url).then(res => res.data);

export default function MeetingDetails() {
    const { data, error } = useSWR("https://layapriya.bringecommerce.site/api/layapriya/get-meet-details", fetcher);
    const [sortOrder, setSortOrder] = useState("asc");
    const [filterToday, setFilterToday] = useState(false);
    const [showFutureOnly, setShowFutureOnly] = useState(true); // Default to show only future dates

    const handleSortOrderChange = () => {
        setSortOrder(prev => (prev === "asc" ? "desc" : "asc"));
    };

    const handleTodayFilter = () => {
        setFilterToday(prev => !prev);
    };

    const handleShowFutureOnly = () => {
        setShowFutureOnly(prev => !prev);
    };

    const filteredAndSortedData = useMemo(() => {
        if (!data?.data) return [];

        let meetings = [...data.data];

        // Filter for future dates
        if (showFutureOnly) {
            const now = moment();
            meetings = meetings.filter(meeting => moment(meeting.event.fromTime).isAfter(now));
        }

        // Filter for today's meetings
        if (filterToday) {
            const todayStart = moment().startOf('day');
            const todayEnd = moment().endOf('day');
            meetings = meetings.filter(meeting => {
                const fromTime = moment(meeting.event.fromTime);
                return fromTime.isBetween(todayStart, todayEnd, null, '[]');
            });
        }

        // Sort by fromTime
        meetings.sort((a, b) => {
            const dateA = moment(a.event.fromTime);
            const dateB = moment(b.event.fromTime);
            return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });

        return meetings;
    }, [data, sortOrder, filterToday, showFutureOnly]);

    if (error) {
        return <Typography variant="h6" align="center">Failed to load meeting details.</Typography>;
    }

    if (!data) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!data.success || data.data.length === 0) {
        return <Typography variant="h6" align="center">No meeting details available.</Typography>;
    }

    return (
        <Box style={{ marginTop: 20, padding: 20 }}>
            <Space style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button onClick={handleSortOrderChange}>
                        Sort by Date ({sortOrder === "asc" ? "Ascending" : "Descending"})
                    </Button> &nbsp;
                    <Button onClick={handleTodayFilter} type={filterToday ? "primary" : "default"}>
                        Today
                    </Button> &nbsp;
                    <Button onClick={handleShowFutureOnly} type={showFutureOnly ? "primary" : "default"}>
                        Show Only Future Dates
                    </Button>
                </div>
                <div>
                    <Typography variant="body1">
                        Count: {filteredAndSortedData.length}
                    </Typography>
                </div>
            </Space>

            <Grid container justifyContent="center">
                {filteredAndSortedData.map((meeting, index) => {
                    const { event, emailList, calendarLink, meetLink } = meeting;
                    const { eventName, eventDescription, fromTime, toTime, timeZone } = event;

                    return (
                        <Grid item xs={12} md={8} key={index} style={{ marginBottom: 20 }}>
                            <Card sx={{ borderRadius: 3 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        {eventName}
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary" gutterBottom>
                                        {eventDescription}
                                    </Typography>
                                    <Box my={2}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Time:
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {new Date(fromTime).toLocaleString()} - {new Date(toTime).toLocaleString()} ({timeZone})
                                        </Typography>
                                    </Box>
                                    <Box my={2}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Emails:
                                        </Typography>
                                        <List>
                                            {emailList.map((item, index) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>
                                                        <EmailIcon color="primary" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.email} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                    <Space style={{ marginTop: 20 }}>
                                        <Button type="primary" icon={<CalendarOutlined />} href={calendarLink} target="_blank">
                                            View G-Calendar
                                        </Button>
                                        <Button type="dashed" icon={<VideoCameraOutlined />} href={meetLink} target="_blank">
                                            Join Meeting
                                        </Button>
                                    </Space>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}
