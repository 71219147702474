import React, { useState, useEffect } from "react";
import "./style.css";

const Checkout = ({ userId }) => {
  const [userIdFromStorage, setUserIdFromStorage] = useState('');

  useEffect(() => {
    // Get userId from localStorage if not passed as prop
    const storedUserId = window.localStorage.getItem('userId');
    if (!userId && storedUserId) {
      setUserIdFromStorage(storedUserId);
    }
  }, [userId]);

  useEffect(() => {
    // Automatically proceed to checkout once userId is available
    const finalUserId = userId || userIdFromStorage;
    if (finalUserId) {
      const form = document.createElement('form');
      form.action = `https://layapriya.bringecommerce.site/api/checkout-stripe/${finalUserId}`;
      form.method = "post";
      document.body.appendChild(form);
      form.submit();
    }
  }, [userId, userIdFromStorage]);

  return (
    <div className="checkout-container">
      <p>Redirecting to checkout...</p>
    </div>
  );
};

export default Checkout;
