import React, { useState } from "react";
import "./GuruProfile.css";
import guru from "./media/guru.png";
import ravishankar2 from "./media/RaviShankar2.jpg";
import krmkeerthy from "./media/krmkeerthy.png";
import karaikudi from "./media/karaikudi.png";
import YoutubeLinks from "./YoutubeLinks";
import jahnavi from "./media/jahnavi.jpg"
import ArticleLinks from "./ArticleLinks";

const GuruProfile = () => {
  const [showFullProfile, setShowFullProfile] = useState(false); // State for "Read More"
  const [showFullContributions, setShowFullContributions] = useState(false); // State for "Learn More"

  // Toggle functions
  const handleReadMore = () => {
    setShowFullProfile(!showFullProfile);
  };

  const handleLearnMore = () => {
    setShowFullContributions(!showFullContributions);
  };

  return (
    <>
      <div className="guru-container">
        <section className="profile-section">
          <div className="profile-content">
            <h1>M.T. Rajakesari: A Master of Mridangam</h1>
            <div className="profile-image-container-rajkeshari">
              <center>
                <img src={guru} className="profile-image-rajkeshari" />
              </center>
            </div>
            <p>
              M.T. Rajakesari, a luminary of Mridangam from Karnataka, India,
              stands as a testament to the enduring legacy of his mentor, the
              legendary Guru Karaikudi R. Mani. Imbued with a profound
              understanding of Karnatik music, Rajakesari's mathematical
              brilliance and rhythmic virtuosity have captivated audiences
              worldwide. A dedicated custodian of this classical art form, he
              has devoted his life to preserving its rich heritage through
              countless performances across the globe.
            </p>
          </div>

          <section className="contributions-section">
            <h2>A Legacy of Performances and Mentorship</h2>
            <p>
              Rajakesari's contributions to the field of Karnatik music are
              immeasurable. He has shared the stage with numerous renowned
              artists, including K.V. Narayanaswamy, Dr. M. Balamuralikrishna,
              D.K. Jayaraman, Lalgudi Jayaraman, Dr. N Ramani, and more.
            </p>
            <p>
              He has nurtured emerging talent, contributing significantly to the
              careers of many contemporary artists like Shashank, Guruprasanna,
              Udupa, and more.
            </p>
          </section>

          {/* Guiding Light to Keerthy Raghavan */}
          <section className="guiding-light-section">
            <h2>A Guiding Light to Keerthy Raghavan</h2>
            <div className="guiding-light-content">
              <p>
                Keerthy Raghavan, a brilliant disciple of Rajakesari, embodies
                the essence of his teachings. With a unique style that fuses
                tradition and innovation, Keerthy has made significant strides
                in the world of Karnatik music, bringing a fresh perspective
                while honoring the legacy of her guru.
              </p>
            </div>
          </section>

          {/* Rhythm across Continents Section */}
          <section className="achievements-section">
            <h2>Rhythm across Continents & Achievements</h2>
            <p>
            Rajakesari's impact extends far beyond India. His performances at prestigious venues such as the  Conservatorium van Amsterdam and his conducting of workshops, and teachings in Pittsburgh,  USA have solidified his reputation as a global figure in Karnatik music. His dedication to this art  form is further evident in his role as an All India Radio "A" top-grade staff artiste and his  administrative responsibilities at the Sruti Laya Kendra, Bangalore chapter, founded by his Guru. 
Rajakesari's exceptional contributions have been duly recognized with numerous awards, including  the Sangeeta Laya Kala Pratibhamani, Sangeeta Kala Tapasvi, Kala Deepti, Laya Vadya Kala  Bhooshana, Palghat Mani Iyer Award, Karnataka Kalashri -highest artist award bestowed by the  Government of Karnataka. His legacy as a master of Mridangam continues to inspire and influence  generations of musicians, ensuring that this timeless art form thrives for years to come. 


            </p>
         
          </section>

          <section className="videos-section">
            <h2>Guru's Concerts</h2>
            <div className="video-list">
              <div className="video-item">
                <iframe
                  src="https://www.youtube.com/embed/0r34Pq9OFig"
                  title="Vaibhavam 2016 Taala Vaadya Laya Samvaada"
                  className="video"
                  allowFullScreen
                ></iframe>
                <p>Vaibhavam 2016 Taala Vaadya Laya Samvaada</p>{" "}
                {/* Title below video */}
              </div>
              <div className="video-item">
                <iframe
                  src="https://www.youtube.com/embed/51XiQbYkxio"
                  title="Varnam: Raga Begada"
                  className="video"
                  allowFullScreen
                ></iframe>
                <p>Varnam: Raga Begada</p> {/* Title below video */}
              </div>
              <div className="video-item">
                <iframe
                  src="https://www.youtube.com/embed/SJ37uPmROP0"
                  title="Sri.K.S.Gopalakrishnan"
                  className="video"
                  allowFullScreen
                ></iframe>
                <p>Sri.K.S.Gopalakrishnan</p> {/* Title below video */}
              </div>
            </div>
          </section>
        </section>
      </div>

      <div className="guru-container">
        <section className="profile-section">
          <div className="profile-content">
            <h1>Bangalore K. Ravi Shankar: A Mridangam Mentor</h1>
            <div className="profile-image-container-Shankar">
              <center>
                <img src={ravishankar2} className="profile-image-Shankar" />
              </center>
            </div>
            <p>
              Bangalore K. Ravi Shankar is a renowned Mridangam artist from
              Karnataka, India. As a disciple of the legendary Guru Karaikudi R.
              Mani, he received a profound education in the Gurukula tradition,
              a holistic system emphasizing spiritual, intellectual, and moral
              development. Trained at the prestigious school Shruti Laya Kendra,
              founded by his Guru, Ravishankar has excelled as an All India
              Radio and Doordarshan artist. His performances have graced
              prestigious venues like Bangalore Gayana Samaja and Madras Music
              Academy, where he has collaborated with renowned musicians such as
              Sri.Tanjavoor S. Kalyanaraman, Sri.Kadari Gopalnath, Smt. Sudha
              Raghunathan, Sri. R.K. Srikantan, Smt.M.S.Sheela, Smt. Ramamani,
              and many more. Beyond his performing career, Ravishankar has been
              a dedicated teacher and mentor. He has served as a preceptor at
              the Sruti Laya Kendra Bangalore Chapter, a visiting tutor at the
              UK chapter, and a full-time faculty member at the Temple of Fine
              Arts Malaysia. Under his mentorship, countless mridangam students
              have blossomed into accomplished musicians, achieving the
              prestigious milestone of Rangapravesham/Arangetram, where they are
              qualified to grace the concert stage.His workshops have been
              conducted at prestigious institutions like the University of
              Newcastle and international music festivals. Ravishankar has
              achieved a remarkable degree of mastery over the tonal quality of
              the Vadayam. His exceptional skills as a mridangam player are
              abundantly evident in his ability to effortlessly execute the
              instrument's intricate rhythms and subtleties. He is dedicated to
              advancing this traditional art form and the "Karaikudi Mani Bani"
              by sharing his knowledge and expertise with aspiring musicians.
              Ravishankar specializes in guiding emerging performers, providing
              them with invaluable mentorship and technical instruction on
              “Sadhana”. Ravishankar played a pivotal role in shaping Keerthy
              Raghavan's career as a mridangam artist. His invaluable
              instruction on effective practice techniques undoubtedly
              contributed to his remarkable achievements. Ravishankar's
              mentorship serves as a testament to his dedication to nurturing
              young talent and preserving the rich traditions of Karnatik music.
              Currently, he serves as a special faculty member at the Laya Priya
              School of Music, Vancouver Canada, providing remote support and
              guidance to the school. His dedication to teaching and his global
              reach solidify his position as a leading figure in the world of
              Karnatik music.
            </p>
          </div>
        </section>
      </div>

      <div className="guru-container">
        <section className="profile-section">
          <div className="profile-content">
            <h1>
              Remembering Guru Karaikudi R Mani <br/>A pioneer of Mridangam, 
              Karnatik Percussion - 1945-2023
            </h1>
            <div className="profile-image-container-Karaikudi">
              <center>
                <img src={karaikudi} className="profile-image-Karaikudi" />
              </center>
            </div>
            <p>
              Guru Karaikudi R. Mani, a titan in the realm of Karnatik music,
              was more than merely a stalwart or a legend; he was a veritable
              ruler of rhythm, a trendsetter whose innovative approach to the
              Mridangam inspired millions across the globe. His artistry
              transcended geographical boundaries, captivating audiences far
              beyond the shores of India. A visionary who redefined the
              possibilities of percussion, Mani's legacy serves as a beacon,
              guiding countless musicians and elevating the Mridangam to
              unprecedented heights. Under the spiritual tutelage of Guru
              Surajananda, a Kundalini Yogi with a lineage of Himalayan masters,
              Guru Mani embarked on a profound journey of spiritual evolution.
              Renouncing worldly pursuits in favour of a life dedicated to the
              RajaVadayam (Kind of Percussion) Mridangam, Guru Mani became more
              than just a master of the instrument; but a creator of legends and
              leaving an enduring legacy that continues to inspire many
              musicians worldwide.
            </p>

          
          </div>

          <section className="contributions-section">
            <h2>
              The "Karaikudi Mani Bani” - A style that mesmerised the Masses and
              created the Class.
            </h2>
            <p>
              Guru Mani's innovative spirit and groundbreaking approach to the
              Mridangam left an indelible mark on Karnatik music. He pioneered a
              distinctive style, characterised by his intricate rhythmic
              patterns, clarity of thought & strokes and creative exploration of
              the Mridangam's tonal variations brought the fame as "Karaikudi
              Mani Bani” or Karaikudi Mani Style . He was a pioneer in the field
              of percussion ensembles, revolutionising the landscape of Karnatik
              music. By showcasing the harmonious interplay of multiple
              percussion instruments without vocal or melodic accompaniment, he
              demonstrated the immense expressive potential of this unique
              ensemble format. His innovative approach to percussion ensembles
              captivated audiences worldwide, with one event reaching over forty
              thousand people conducted by Bengal foundation. This achievement
              solidified his reputation as a visionary and earned him admiration
              from legends of world music. He not only captivated audiences but
              also inspired a new generation of Rasikas who came to appreciate
              the profound beauty and complexity of this art form.
            </p>
          </section>

          {/* Guiding Light to Keerthy Raghavan */}
          <section className="guiding-light-section">
            <h2>
              A Revolution in Tani Avartanam (Solo percussion performance)
            </h2>
            <div className="guiding-light-content">
              <p>
                Guru Karaikudi Mani's influence on Karnatik music extended far
                beyond his virtuosic skills on the Mridangam. During the era
                when Taniyavartanam, or solo percussion performances, were often
                overlooked and treated as a mere interlude, Guru Mani
                revolutionised the perception of this art form characterised by
                their intricate rhythms, precision, and emotional depth,
                captivated audiences and transformed Taniyavartanam into a
                highly anticipated and sought-after event. Mani's artistry
                elevated the status of the Mridangam, making it a focal point of
                concerts and attracting a dedicated following of Rasikas, or
                connoisseurs of Karnatik music. His performances were so
                mesmerising that audiences would often stay for the entire
                duration of the Taniyavartanam, a testament to his extraordinary
                talent. He was a highly sought-after accompanist for vocalists
                and other musicians, and his presence alone could elevate the
                quality of concert. Aspiring Mridangam artists looked up to him
                as a role model, and striving to emulate his mastery and
                innovation.
                <br />
                <p style={{ fontStyle: "italic", marginTop: "2rem" }}>
                  Fans spoke of him in the same breath as legends such as
                  Palghat Mani Iyer and Palani Subramaniam Pillai whom Mani
                  revered. At Kutchers people in the audience often walkout of
                  the hall for a short break when the Taniyavartanam Start. Mani
                  Ensured that no one moved, except to the edge of the seat. -{" "}
                  <a href="http://tiny.cc/5x7ozz">Times of India</a>
                </p>
              </p>
              <br />
            </div>
          </section>

          {/* Rhythm across Continents Section */}
          <section className="achievements-section">
            <h2>Global Influence</h2>
            <p>
              Guru Mani's debut album, "Sruthi Laya," was a resounding success,
              selling millions of copies both domestically and internationally.
              This album ignited a global percussion wave, propelling Mani to
              international acclaim and leading to numerous performances and
              workshops around the world. Sruthi Laya ensemble has captivated
              audiences worldwide. This innovative group features top- ranking
              Indian percussionists, with the Mridangam taking center stage.
              Sruthi Laya has collaborated with renowned international
              ensembles, such as the Australian Art Orchestra, led by Paul
              Grobowsky, and Nada, led by Eerohameenniemi from Finland. These
              collaborations have showcased the versatility and expressive power
              of Karnatik percussion on a global stage.
            </p>
          </section>

          <section className="achievements-section">
            <h2>
              Shruti Laya Kendra - Nurturing the Legacy of Karnatik Music &
              Percussion
            </h2>
            <p>
              Guru Karaikudi Mani's enduring legacy is exemplified by the
              establishment of the Sruti Laya Kendra, a premier institution
              dedicated exclusively to the cause of music, musicians, and
              students. As the first institution of its kind to be led by a
              percussionist, Sruti Laya Kendra has played a pivotal role in
              nurturing and promoting Karnatik music. Through its comprehensive
              programs, the institution has educated thousands of students in
              the art of the Mridangam, ensuring that this rich musical
              tradition continues to thrive for generations to come. Expanding
              their reach beyond India, Sruthi Laya Kendra have established
              chapters in cities across India (Chennai, Bangalore , Chalakudi,
              Hyderabad) as well as in Australia, the United Kingdom, Germany
              and United States of America. This global network serves as a
              testament to Guru Mani's vision and the enduring impact of his
              legacy on Karnatik music.
            </p>
          </section>

          <section className="achievements-section">
            <h2>
              The Rhythm of life: Mani Sir's influence on Keerthy Raghavan.
            </h2>

            <div>
              <center>
                <img src={krmkeerthy} className="profile-image-krm" />
              </center>
            </div>
            
            <p>
              Guru Mani has not merely imparted technical expertise to Keerthy
              Raghavan but has also served as a guiding star illuminating his
              life's journey. His profound insights into the nuances of
              existence, both within and beyond the musical realm, have sculpted
              Keerthy into a person of depth and character.
            </p>
          </section>

          <section className="achievements-section">
            <h2>
              A Fitting Tribute: Karaikudi R. Mani Honoured in India's New
              Parliament
            </h2>
            <p>
              As a testament to his enduring legacy, the Government of India has
              requested the Shruti Laya Kendra, Chennai, to donate one of Guru
              Karaikudi R. Mani's cherished instruments for display in the
              nation's new Parliament building. This gesture serves as a fitting
              tribute to the maestro, whose contributions to Karnatik music have
              left an indelible mark on India's cultural heritage.
            </p>
            <p>
              Today, the showcase panel number 4 in the Sangeet Deergha section
              of the new Parliament building proudly features Karaikudi R.
              Mani's instrument, a tangible reminder of his extraordinary
              artistry and the enduring impact he has had on the world of music.
              - <a href="https://shorturl.at/D8ioU">Link</a>
            </p>
          </section>

          <section className="videos-section">
            <h2>Guru's Concerts</h2>
            <div className="video-list">
              <div className="video-item">
                <iframe
                  src="https://www.youtube.com/embed/0r34Pq9OFig"
                  title="Vaibhavam 2016 Taala Vaadya Laya Samvaada"
                  className="video"
                  allowFullScreen
                ></iframe>
                <p>Vaibhavam 2016 Taala Vaadya Laya Samvaada</p>{" "}
                {/* Title below video */}
              </div>
              <div className="video-item">
                <iframe
                  src="https://www.youtube.com/embed/51XiQbYkxio"
                  title="Varnam: Raga Begada"
                  className="video"
                  allowFullScreen
                ></iframe>
                <p>Varnam: Raga Begada</p> {/* Title below video */}
              </div>
              <div className="video-item">
                <iframe
                  src="https://www.youtube.com/embed/SJ37uPmROP0"
                  title="Sri.K.S.Gopalakrishnan"
                  className="video"
                  allowFullScreen
                ></iframe>
                <p>Sri.K.S.Gopalakrishnan</p> {/* Title below video */}
              </div>
            </div>
          </section>
        </section>

        <YoutubeLinks />
        <ArticleLinks />
      </div>

      <div className="guru-container">
        <section className="profile-section">
          <div className="profile-content">
            <h1>A loving memory of Vidushi Jahnavi Jayaprakash (1950 -2002)</h1>
            <div className=".profile-image-container-rajkeshari">
              <center>
                <img src={jahnavi} className="profile-image-rajkeshari" />
              </center>
            </div>
            <p>
              The magic of her magnificent voice, her sense of proportion and
              the emotional content of her singing are just some of the
              qualities that made Jahnavi Jayaprakash the foremost and most
              popular vocalist of Karnataka of recent decades. In Amsterdam, she
              had a profound and lasting influence on a whole generation of
              musicians. She was endowed with a rich and melodious voice,
              encompassing three octaves with ease, which had precision, tone
              perfection and clarity. The much-travelled Jahnavi was born in
              1950 into an illustrious family of poets, composers and
              musicologists enjoying a cherished place in the literary and music
              world of Karnataka. She is the paternal aunt of Keerthy Raghavan,
              who ignited a passion for Carnatic music, leading him to seek the
              tutelage of the renowned Mridangam artist, M.T. Rajekesari, an
              All-India Radio A Top-list performer. Jahnavi's training in
              classical Karnatik music began at an early age, having had the
              good fortune of learning from such stalwarts in the field as Sri.
              T. Puttaswamaiah of Bangalore and Sri. H.V. Venkateshaiah of
              Holenarasipur, who groomed her in the various aspects of this
              multifaceted and arduous art form. Besides being well versed in
              the practical side of Indian classical music, she also had a deep
              theoretical background in the subject, having taken a master’s
              degree in music at Bangalore University in 1977.
            </p>
            <p>
              A performing artiste of extraordinary caliber, Jahnavi gave
              innumerable vocal recitals at important cultural centers all over
              India and abroad, particularly in the USA, Europe, Canada, Middle
              and the Far East. She participated in many International and
              multicultural festivals of Music and Dance in both the USA and
              Europe. In 1990, she participated in the festival of India
              celebrations held in Los Angles and San Francisco, as well as the
              prestigious Los Angeles Festival of Music and Dance. Jahnavi's
              forte lay in her capacity to sing for classical Indian dancing.
              This unique talent endeared her to the entire dancing fraternity.
              She accompanied almost all the leading Indian classical dancers,
              the world over and she co-produced major dance performances in USA
              at the Japan America Theater, Long Beach Convention Center,
              Dorothy Chandler Pavilion etc. In the year 1990, she provided
              music for "The Mahabharata", a dance drama created by Peter Brook
              performed at the Los Angeles festival of music and dance.
              Jahnavi's artistic proficiency won her many bequests and
              accolades: she received the state award for her first place in the
              art faculty of the Bangalore University in the year 1977. In
              recognition and appreciation of her talent she was also conferred
              with the titles "Swarna Kokila" and "Sangeetha Sharade", and in
              1997 she was awarded the title "Karnataka Kalashree".
            </p>
            <p>
              During a European tour in 1993, she came into contact with Rafael
              Reina (http:// www.rafaelreina.org/rafael-reina.html), a composer
              and senior teacher at the Amsterdam Conservatory. He studied with
              her for several years and subsequently created a curriculum at the
              Conservatory of Amsterdam called "Applications of Karnatik Rhythm
              to Contemporary Music" ( https://rb.gy/ttusak ). This milestone
              opened doors for many Karnatik musicians to gain access to the
              European market, such as B.C. Manjunath, Varijashree Venugopal,
              Mysore Manjunath, and many more.
            </p>

            <p>
              In 1998 Jahnavi was invited to conduct a weeklong seminar and
              workshop on the "Science and Art of Karnatik Music", which was
              organized jointly by the Conservatory and the International
              Society for Microtonal Music (Huygens-Fokker Foundation). As part
              of this event, a series of concerts were arranged for Jahnavi
              throughout the Netherlands in collaboration with several
              prestigious Dutch organizations. Her musicality and teachings were
              so captivating that she returned to the Netherlands in 2000, 2001
              and 2002 for more workshops and concerts and she taught many
              musicians who began regularly experimenting with what they had
              learned at the Karnatik Lab. Always striving for innovation,
              Jahnavi also participated in several musical collaborations,
              including a crossover project organized by the Kulsan Foundation
              with Turkish musicians. "A visionary philanthropist, she
              established the Vidya Sundari Bengaluru Nagaratnamma Trust to
              honor the often-overlooked legend of Nagaratnamma. Nagaratnamma, a
              patron of the arts, constructed the magnificent Shri Thyagaraja
              Swamy Temple in Thiruvarur, a pilgrimage site for musicians
              worldwide. Every year, thousands of artists gather on the banks of
              the Kaveri River to celebrate the birth of Thyagaraja, one of
              Karnatik music's greatest composers.
            </p>
            <p>
              In her unwavering dedication to preserving cultural heritage, she
              co-founded the Raja Rajeshwari Nagar cultural organization. This
              institution serves as a beacon for the arts, fostering a vibrant
              community where music, dance, folklores, and traditions flourish.
              The annual Sri Ramotsava, Navaratri festivals, and monthly
              concerts continue to draw thousands of devotees and enthusiasts,
              ensuring that the rich tapestry of Indian culture remains alive."
              While she already had had a profound effect, the feeling in
              Amsterdam in 2002 was that her teachings were just beginning when
              she died suddenly in April of a heart attack at the age of 52. Her
              students, fans and colleagues throughout India, North America and
              Europe were shocked. The loss suffered that day made no sense
              because she was a great artist in her prime; one whose influence
              was just starting to rapidly effect new circles of people.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default GuruProfile;
