// src/SocialMediaPage.js
import React from 'react';
import { FaFacebook, FaInstagram, FaNewspaper, FaYoutube } from 'react-icons/fa';
import './SocialMediaPage.css'; // Import the CSS file

const SocialMediaPage = () => {
    const socialLinks = [
        {
            name: 'Instagram',
            url: 'https://www.instagram.com/rhythm_raghavan/',
            icon: <FaInstagram />,
        },
        {
            name: 'Facebook',
            url: 'https://www.facebook.com/profile.php?id=61564087856244',
            icon: <FaFacebook />,
        },
        {
            name: 'YouTube (Rhythm Raghavan)',
            url: 'https://www.youtube.com/@Rhythm.Raghavan',
            icon: <FaYoutube />,
        },
        {
            name: 'YouTube (Shruti Laya Bhedam)',
            url: 'https://www.youtube.com/@ShrutiLayaBhedam',
            icon: <FaYoutube />,
        },
        {
            name: 'Media Coverage',
            url: 'https://issuu.com/riverdistrictlivingmagazine/docs/river_district_living_magazine_-_issue_08_-_digita',
            icon: <FaNewspaper />
        },
    ];

    return (
        <div className="social-media-container">
            <h1>Connect with Rhythm Raghavan</h1>
            <div className="social-buttons" style={{margin:"1rem"}}>
                {socialLinks.map((link) => (
                    <a key={link.name} href={link.url} target="_blank" rel="noopener noreferrer" className="social-button">
                        {link.icon}
                        <span>{link.name}</span>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default SocialMediaPage;
