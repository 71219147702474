import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ErrorPage.css';

const ErrorPage = () => {
  const history = useNavigate();

  const redirectToHome = () => {
    history('/');
  };

  return (
    <div className="error-page">
      <div className="error-container">
        <h1 className="error-title">404</h1>
        <p className="error-message">Oops! The page you're looking for doesn't exist.</p>
        <button className="home-button" onClick={redirectToHome}>Go to Home</button>
      </div>
    </div>
  );
};

export default ErrorPage;
