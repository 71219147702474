import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles.css";

export default function Layapriya() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <>
      <div className="layapriya-container" data-aos="fade-up">
        <p className="layapriya-description">
          The LayaPriya School of Performing Arts, Vancouver, is a sanctum
          dedicated to preserving the intricate fabric of South Indian Classical
          Karnatik Music and Rhythm. We are a dedicated collective of performers
          influenced by scholars and musicologists, united in our pursuit of
          excellence. Our mission is to ignite a passion for sound within the
          minds of aspiring musicians, nurturing their talents from early
          childhood and fostering a lifelong appreciation for this profound art
          form.
          <br />
          <br />
          Our mandate is to elevate the Karnatik Music & Mridangam, a revered
          percussion instrument, to a global stage, preserving its rich heritage
          and inspiring future generations of musicians. By disseminating the
          knowledge of the renowned Karaikudi Mani Bani, a new age mridangam
          style, we seek to foster a deeper appreciation for the instrument's
          intricate rhythms developed by Guru Karaikudi R Mani.
        </p>
      </div>

      <div>
        <p
          className="layapriya-description"
          style={{ color: "black", paddingTop: "2rem", paddingBottom: "2rem" }}
        >
          Karnatik music is one of the most sophisticated oldest systems of
          classical music, rooted in South India. Its foundation lies in a
          highly structured system that emphasizes raga (melody) and tala
          (rhythm), offering limitless possibilities for improvisation and
          expression. Indian classical music traces its roots to the Samaveda,
          one of the four foundational texts of the Vedas, dating back over
          24,000 years (according to scholar Nilesh Oak). This ancient scripture
          serves as the bedrock of Indian classical music, shaping its intricate
          melodies, rhythmic structures, and spiritual significance.
        </p>
      </div>
    </>
  );
}
