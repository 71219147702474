import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Paper,
} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import axios from "axios";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import registration from "./media/registration.jpg"; // Ensure you have this image
import golden from "./media/golden.png"

const steps = ["Enter Email", "Verify PIN", "Success"];

const RegisterV2 = () => {
  const [formData, setFormData] = useState({ email: "", pin: "", payment: "" });
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [insertionId, setInsertionId] = useState("");
  const [canRequestOTP, setCanRequestOTP] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [dropdownError, setDropdownError] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangeDropdown = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    let timer;
    if (step === 1) {
      timer = setInterval(() => {
        checkOtpStatus();
      }, 60000);
    }
    return () => clearInterval(timer);
  }, [step]);

  const checkOtpStatus = async () => {
    try {
      const response = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya-v2/search-email",
        { email: formData.email }
      );
      const { status } = response.data;
      setCanRequestOTP(status !== "error");
    } catch (error) {
      showSnackbar("Failed to check OTP status. Please try again.", "error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStep = async () => {
    if (step === 0 && validateEmail()) {
      await handleEmailValidation();
    } else if (step === 1 && validatePin()) {
      await handlePinVerification();
    } else if (step === 2 && validatePayment()) {
      handlePayment();
    }
  };

  const handleEmailValidation = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya-v2/search-email",
        { email: formData.email }
      );
      const { status, message } = response.data;
      if (status === "success") {
        showSnackbar(message, "success");
        setStep(1);
      } else {
        showSnackbar(message, "error");
      }
    } catch (error) {
      showSnackbar("Failed to validate email. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePinVerification = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya-v2/otp-verify",
        { email: formData.email, otp: formData.pin }
      );
      const { status, message, _id } = response.data;
      if (status === "EMAIL_VERIFIED") {
        showSnackbar("OTP verified successfully!", "success");
        setInsertionId(_id);
        setStep(2);
      } else {
        showSnackbar(status, "error");
      }
    } catch (error) {
      showSnackbar("OTP verification failed. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      showSnackbar("Payment successful! Registration complete.", "success");
      setStep(3);
    } catch (error) {
      showSnackbar("Payment failed. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      showSnackbar("Please enter a valid email address.", "error");
      return false;
    }
    return true;
  };

  const validatePin = () => {
    if (formData.pin.length !== 6) {
      showSnackbar("PIN must be 6 digits.", "error");
      return false;
    }
    return true;
  };

  const validatePayment = () => {
    if (formData.payment === "") {
      showSnackbar("Please complete payment to proceed.", "error");
      return false;
    }
    return true;
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <Box textAlign="center" sx={{ p: 3 }}>
          
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNextStep}
              disabled={loading}
              sx={{ mt: 2, borderRadius: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : "Next"}
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box textAlign="center" sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Enter Your PIN
            </Typography>
            <TextField
              fullWidth
              label="PIN"
              name="pin"
              type="password"
              value={formData.pin}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNextStep}
              disabled={loading || !canRequestOTP}
              sx={{ mt: 2, borderRadius: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : "Validate PIN"}
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box textAlign="center" sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Registration Successful!
            </Typography>
            <Typography variant="body1">
              Thank you for registering. You will receive a confirmation email shortly.
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        width: '100%',
        // backgroundImage: `url(${registration})`,
        // backgroundColor: 'grey',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >

      
      <Paper elevation={4} sx={{
        padding: 3,
        borderRadius: 2,
        backgroundColor: "rgba(255, 255, 255, 0.2)", // Semi-transparent white
        maxWidth: 600,
        width: '100%',
        backdropFilter: 'blur(5px)', // Optional: blur the background
      }}>
          <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{ mb: 3, fontWeight: "600", color: "black", fontSize:"24px" }}
              style={{
                fontFamily: '"Noto Serif", serif'
              }}
            >
              Unleash Your Inner Musician - Sign up Now
            </Typography>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent()}
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RegisterV2;
