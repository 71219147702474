import React from "react";
import "./ShrutiLayaBhedam.css"; // Import custom CSS for advanced styling
import jagathi2 from "./jagathi2.jpeg";

const ShrutiLayaBhedam = () => {
  return (
    <div className="shruti-container" data-aos="fade-up">
      <div id="box-search">
        <div class="thumbnail">
          <img
            src={jagathi2}
            alt="Shruti Laya Bhedam"
            className="responsive-img"
          />
          <div class="caption">
            <p>A Shruti Laya Bhedam - Symphony of Cultural Fusion</p>
          </div>
        </div>
      </div>

      <div className="shruti-content" data-aos="fade-up">
        <p>
          Shruti Laya Bhedam, a Vancouver-based ensemble, stands as a testament
          to the harmonious convergence of traditional Indian Karnatic classical
          music and contemporary genres. The name itself, derived from Sanskrit,
          signifies "Melody, Rhythm, and Variation," suggests that the
          ensemble's music is characterised by a harmonious interplay of
          different musical styles and influences. Comprised of exceptionally
          talented artists who have honed their skills under esteemed maestros
          for over two decades, Shruti Laya Bhedam has been captivating
          audiences in Vancouver since 2023. Each member brings a wealth of
          experience and expertise to the ensemble, creating a synergy that is
          both powerful and inspiring.
        </p>
        <p>
          Beyond their exceptional musical abilities, Shruti Laya Bhedam is
          deeply committed to fostering cross-cultural collaborations and
          promoting social change. As an ardent advocate for peace and unity,
          Keerthy Raghavan, the ensemble's visionary, curates thematic musical
          experiences that address pressing global issues such as racism and
          discrimination. Through their performances, Shruti Laya Bhedam seeks
          to educate, inspire, and unite audiences from diverse backgrounds,
          while also pushing the boundaries of musical expression. Shruti Laya
          Bhedam's dedication to musical excellence and their unwavering
          commitment to social causes make them a truly remarkable ensemble.
          Their performances are a testament to the power of music to transcend
          cultural boundaries, inspire change, and bring people together.
        </p>
      </div>

      {/* Additional Sections */}
      <section className="videos-section">
        <div className="video-list">
          <div className="video-item">
            <iframe
              src="https://www.youtube.com/embed/N-7detAGL4w" // Replace with the actual YouTube video ID
              title="Shruti Laya Bhedam Ensemble"
              className="video"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-item">
            <iframe
              src="https://www.youtube.com/embed/2K7rgI9pQZg" // Replace with the actual YouTube video ID
              title="Shruti Laya Bhedam Squad"
              className="video"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-item">
            <iframe
              src="https://www.youtube.com/embed/vRjQq299OlQ" // Replace with the actual YouTube video ID
              title="Final Video of Shruti Laya Bhedam"
              className="video"
              allowFullScreen
            ></iframe>
          </div>

          <div className="video-item">
            <iframe
              src="https://www.youtube.com/embed/b-svUv3cj4g" // Replace with the actual YouTube video ID
              title="Final Video of Shruti Laya Bhedam"
              className="video"
              allowFullScreen
            ></iframe>
          </div>

          <div className="video-item">
            <iframe
              src="https://www.youtube.com/embed/WDBOT0AD2n8" // Replace with the actual YouTube video ID
              title="Final Video of Shruti Laya Bhedam"
              className="video"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShrutiLayaBhedam;
