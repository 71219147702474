import React from "react";

export default function FailedStripe() {
    const handleContactSupport = () => {
        window.location.href = "mailto:support@layapriya.ca?subject=Payment%20Issue";
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <div style={styles.icon}>❌</div>
                <h2 style={styles.title}>Payment Failed</h2>
                <p style={styles.message}>
                    Oops! Something went wrong with your payment. Please try again or contact support for assistance.
                </p>
                <button style={styles.button} onClick={handleContactSupport}>
                    Contact Support
                </button>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f8d7da',
    },
    card: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        textAlign: 'center',
        maxWidth: '400px',
        width: '100%',
    },
    icon: {
        fontSize: '50px',
        color: '#e3342f',
    },
    title: {
        fontSize: '24px',
        margin: '20px 0',
        color: '#721c24',
    },
    message: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '20px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#e3342f',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
};

