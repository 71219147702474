import React from "react";

export default function ChamberConcerts() {
  return (
    <>
     <div className="tutor-container">
      <section className="about-section" data-aos="fade-up">
        <div className="about-content" style={{ textAlign: "center" }}>
          <h2>Chamber Concerts</h2>
          <p style={{ fontSize: "22px", textAlign: "center" }}>
          Kapi, a Kannada word signifying "coffee," embodies the warmth and comfort associated with this beloved beverage.
          <br/>
          "Kapi with Keerthy" is an intimate chamber concert series curated by Keerthy Raghavan under  LayaPriya School of Performing Arts, Vancouver.
          <br/>
          These concert series aim to create an immersive soul-searching atmosphere through the enchanting world of Indian classical music.
          </p>
          <p style={{ fontSize: "22px", textAlign: "center" }}>
            We are dedicated to fostering a vibrant and inclusive local art
            scene in Vancouver by uniting the diverse expressions of Indian
            classical music. To achieve this goal, we will be organizing
            intimate chamber concerts that showcase the beauty and complexity of
            this rich musical tradition.    
            <br />
            If you are passionate about Indian classical music and interested in
            becoming a member of our exclusive community, please reach out to
            us.
          </p>
        </div>
      </section>
      </div>
    </>
  );
}
