import React, { createContext, useState, useEffect } from 'react';
import { validateToken } from '../services/auth';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({ user: null, isAuthenticated: false });
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                // const { data } = await validateToken();
                // if (data.status === "success") {
                //     setAuthState({ user: data.user, isAuthenticated: true });
                // }
                // else{
                //     navigate("/")
                // }
            } catch (err) {
                console.error("Token validation failed", err);
            }
        };
        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ authState, setAuthState }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
