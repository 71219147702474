import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Input, Button, Typography } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "axios";

const { Content } = Layout;
const { Title } = Typography;

export default function PreProtectedSuperAdmin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const loginResponse = await axios.post(
        "https://layapriya.bringecommerce.site/api/layapriya/login/super-admin",
        {
          email: email,
          password,
        }
      );
      console.log(loginResponse.data);
      if (
        loginResponse.data.status === "success" &&
        loginResponse.data.token &&
        loginResponse.data.user.role === "super-admin"
      ) {
        const superAdminResponse = await axios.get(
          "https://layapriya.bringecommerce.site/api/layapriya/superadmin-route",
          {
            headers: {
              Authorization: `Bearer ${loginResponse.data.token}`,
            },
          }
        );

        if (superAdminResponse.data.role === "super-admin") {
          navigate("/super-admin-conquered");
          window.localStorage.setItem("key-email-id", email);
        } else {
          alert("You do not have super admin permissions.");
        }
      } else {
        alert("Login failed.");
      }
    } catch (error) {
      console.error("Error during login or super admin check:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Content style={{ width: "100%", maxWidth: "400px", padding: "20px", textAlign: "center" }}>
        <Title level={3}>Login</Title>
        <Input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: "16px" }}
        />
        <Input.Password
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          style={{ marginBottom: "16px" }}
        />
        <Button type="primary" block onClick={handleLogin}>
          Login
        </Button>
      </Content>
    </Layout>
  );
}
