import React from 'react';
import './GuruProfile.css';

const YoutubeLinks = () => {
  const videoLinks = [
    'https://www.youtube.com/embed/FEumxNi2zfw',
    'https://www.youtube.com/embed/IsFhjb7YdE4',
    'https://www.youtube.com/embed/Jh-NeF7uix8',
    'https://www.youtube.com/embed/tnibE-quQuU',
    'https://www.youtube.com/embed/aj2K8PGFc1Y',
    'https://www.youtube.com/embed/jivBcJbWolE',
    'https://www.youtube.com/embed/BnI7XhIWzjg',
    'https://www.youtube.com/embed/b3ipdBWI-30',
    // Add more video links as needed
  ];

  return (
    <div className="gallery-container">
      {videoLinks.map((link, index) => (
        <div key={index} className="gallery-item">
          <iframe
            src={link}
            title={`video-${index}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ))}

    </div>
  );
};

export default YoutubeLinks;
