import React from 'react';
import './GuruProfile.css';

const ArticleLinks = () => {
  return (
    <div className="article-container">
      <h2>What Mainstream Media Say About Him</h2>
      <div className="button-container">
        <a href="https://indiaartreview.com/stories/karaikudi-r-mani-obituary-mridangam-exponent/#:~:text=Karaikudi%20R%20Mani,%20a%20distinguished%20and" target="_blank" rel="noopener noreferrer">
          <button className="article-button">Indian Art review</button>
        </a>
        <a href="https://timesofindia.indiatimes.com/city/chennai/karaikudi-mani-who-revolutionised-role-of-mridangam-in-concerts-no-more/articleshow/99993279.cms#:~:text=Karaikudi%20R%20Mani%20died%20in%20Chennai%20on" target="_blank" rel="noopener noreferrer">
          <button className="article-button">T O I</button>
        </a>
        <a href="https://www.thehindu.com/entertainment/music/his-rhythm-will-linger-on-musicians-remember-guru-karaikudi-mani/article66838977.ece#:~:text=Guru%20Karaikudi%20Mani,%20known%20for%20his" target="_blank" rel="noopener noreferrer">
          <button className="article-button">The HINDU</button>
        </a>
      </div>
    </div>
  );
};

export default ArticleLinks;
