import React, { useState, useCallback } from 'react';
import { Layout, Menu, Button, Drawer, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const { Header } = Layout;

const SuperAdminAppbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = useCallback((open) => {
    setDrawerOpen(open);
  }, []);

  const links = [
    { id: 1, title: 'Students Registrations List', href: 'students-list' },
    { id: 2, title: 'Enrolled Students', href: 'enrolled-list' },
    { id: 3, title: 'Schedule Classes', href: 'schedule-classes' },
    { id: 4, title: 'Layapriya Classes', href: 'meet-details' },
    // { id: 5, title: 'Add Notifications', href: 'add-notifications' },
  ];

  const drawerItems = (
    <Menu
      mode="horizontal"
      style={{
        position: 'relative',
  display: 'flex',
  justifyContent: 'center'
      }}
      onClick={({ key }) => {
        navigate(key);
        toggleDrawer(false);
      }}
      items={links.map((link) => ({
        key: link.href,
        label: link.title,
      }))}
    />
  );

  async function logout() {
    try {
      await axios.get("https://layapriya.bringecommerce.site/api/layapriya/logout");
      navigate("/super-admin");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
    <Layout style={{ width: '100vw', overflow: 'hidden', margin: 0, padding: 0 }}>
      <Header style={{ 
        display: 'flex', 
        alignItems: 'center', 
        backgroundColor: '#3f51b5', 
        width: '100vw', 
        padding: '0 24px' 
      }}>
        {/* <Button
          icon={<MenuOutlined />}
          type="text"
          onClick={() => toggleDrawer(true)}
          style={{ color: 'white', fontSize: '18px', marginRight: '16px' }}
        /> */}
        <Typography.Title level={4} style={{ color: 'white', flexGrow: 1, margin: 0 }}>
          Layapriya Governance Platform
        </Typography.Title>
        <Button type="text" style={{ color: 'white' }} onClick={logout}>
          Logout
        </Button>
      </Header>
      {drawerItems}


      {/* <Drawer
        title="Layapriya Governance Platform"
        placement="left"
        onClose={() => toggleDrawer(false)}
        open={drawerOpen}
      >
      </Drawer> */}
    </Layout>
    </>
  );
};

export default SuperAdminAppbar;
