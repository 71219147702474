import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./App.css";
import ScrollToTop from "./scrollToTop";
import PreProtectedSuperAdmin from "./SuperAdmin/PreProtectedSuperAdmin";
import { BrowserRouter as Router } from "react-router-dom";

const windowLocation = window.location;

const renderAppBasedOnHostname = () => {
  if (windowLocation.href.includes("superadmin.layapriya.org")) {
    return <PreProtectedSuperAdmin />;
  } else {
    return (
      <>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </>
    );
  }
};

ReactDOM.render(
  <React.StrictMode>
    {renderAppBasedOnHostname()} {/* Render based on the hostname */}
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
