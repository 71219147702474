import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SuperAdminDashboardStyles.css';
import { Button, Space } from "antd";
import { CalendarOutlined, VideoCameraOutlined } from "@ant-design/icons";


const SuperAdminDashboard = ({ apiUrl }) => {
  const [totalUpcoming, setTotalUpcoming] = useState(0);
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const response = await axios.get(apiUrl);
        setTotalUpcoming(response.data.totalUpcoming);
        setMeetings(response.data.upcomingMeetings);
      } catch (error) {
        console.error('Error fetching meetings:', error);
      }
    };

    fetchMeetings();
  }, [apiUrl]);

  return (
    <div className="meetings-container">
      <h1>Upcoming Meetings</h1>
      <p className="total-count">Total Upcoming: {totalUpcoming}</p>
      <div className="meetings-list">
        {meetings.map((meeting) => (
          <div key={meeting.id} className="meeting-card">
            <h2>{meeting.summary}</h2>
            <p><strong>Description:</strong> {meeting.description || 'No description'}</p>
            <p><strong>Location:</strong> {meeting.location || 'No location'}</p>
            <p><strong>Start:</strong> {new Date(meeting.start.dateTime).toLocaleString()}</p>
            <p><strong>End:</strong> {new Date(meeting.end.dateTime).toLocaleString()}</p>
            {meeting.hangoutLink && (
               <a href={meeting.hangoutLink} target="_blank" rel="noopener noreferrer" className="meet-link">
              <Button type="dashed" icon={<VideoCameraOutlined />} target="_blank">
              Join Meeting
          </Button>
              </a>
            )}
            <a href={meeting.htmlLink} target="_blank" rel="noopener noreferrer" className="calendar-link">
              View in Google Calendar
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
