import React, { useEffect } from "react";
import SuperAdminAppbar from "./SuperAdminAppbar";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import SuperAdminDashboard from "./Dashboard/SuperAdminDashboard";

export default function SuperAdminHomePage() {

  const location = useLocation();
  const navigate = useNavigate();

  function getGreetingInCanada() {
    const options = {
      timeZone: 'America/Toronto', 
      hour12: false,
      hour: 'numeric',
    };
  
    const currentHour = new Intl.DateTimeFormat('en-US', options).format(new Date());
  
    // Determine greeting based on hour
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Night';
    }
  }

  const renderComponent = () => {
    if (location.pathname === '/super-admin-conquered' || location.pathname === '/super-admin-conquered/') {
      return <SuperAdminDashboard apiUrl={'https://layapriya.bringecommerce.site/api/layapriya/get-upcoming-meetings/super-admin'}/>;
    }
    return <Outlet />;
  };
  
  useEffect(() => {
    navigate("/super-admin-conquered/students-list")
  },[])
  return (
    <>
      <SuperAdminAppbar />
      <Container maxWidth="lg">
        <Box mt={4}>
          {/* <Typography variant="h4" gutterBottom>
            {getGreetingInCanada()}
          </Typography>
          */}
         {renderComponent()}
        </Box>
      </Container>
    </>
  );
}
